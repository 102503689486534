var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      staticStyle: { width: "75% !important", "margin-left": "25% !important" },
      attrs: {
        "header-class": "header-class-modal-doc-package",
        "hide-footer": "",
        "dialog-class": "dialog-detail-document",
        "body-class": "dialog-detail-document-body",
        "footer-class": "p-0",
        size: "lg",
        "modal-class": "mui-animation",
        fade: false,
      },
      on: { hidden: _vm.emitEvent },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function ({ close }) {
            return [
              _c(
                "div",
                { staticClass: "d-flex align-items-start flex-row w-100" },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column w-90" },
                    [
                      _c("h2", [_vm._v(_vm._s(_vm.accomodation.acc.name))]),
                      _vm.accomodation.fileName !== ""
                        ? _c(
                            "b-button",
                            {
                              staticClass: "transparent",
                              attrs: { variant: "light" },
                              on: { click: _vm.handleClickViewDocument },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.accomodation.fileName.replaceAll(
                                      "_",
                                      " "
                                    )
                                  ) +
                                  "\n\t\t\t\t"
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-start justify-content-end mt-2 w-10",
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "close",
                          attrs: { type: "button", "aria-label": "Close" },
                          on: {
                            click: function ($event) {
                              return close()
                            },
                          },
                        },
                        [_vm._v("×")]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "b-row",
        {
          style: `margin-top: ${
            _vm.accomodation.fileName === "" ? "25px" : "50px"
          }`,
        },
        [
          _vm.accomodation.acc !== undefined &&
          _vm.accomodation.acc.description !== ""
            ? _c("b-col", [
                _c("p", { staticClass: "p-title" }, [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.FormMSG(1, "Description")) +
                      "\n\t\t\t"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.accomodation.acc.description) +
                      "\n\t\t\t"
                  ),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm.accomodation.publisher
        ? _c(
            "b-row",
            [
              _c("b-col", [
                _c("p", { staticClass: "p-title" }, [
                  _vm._v(
                    "\n\t\t\t\t" +
                      _vm._s(_vm.FormMSG(2, "Published by")) +
                      "\n\t\t\t"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.accomodation.publisher.name) +
                      " " +
                      _vm._s(_vm.accomodation.publisher.firstName)
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("p", { staticClass: "p-title" }, [
              _vm._v(_vm._s(_vm.FormMSG(24, "Publication date"))),
            ]),
            _c("p", [
              _vm._v(
                "\n\t\t\t\t" +
                  _vm._s(
                    _vm.accomodation.acc !== undefined &&
                      _vm.getDate(
                        _vm.accomodation.acc.publishedOn,
                        "DD MMMM YYYY"
                      )
                  ) +
                  "\n\t\t\t\t"
              ),
              _c("span", { staticClass: "divider-dot" }),
              _vm._v(
                "\n\t\t\t\t" +
                  _vm._s(
                    _vm.accomodation.acc !== undefined &&
                      _vm.getDate(_vm.accomodation.acc.publishedOn, "HH:mm")
                  ) +
                  "\n\t\t\t"
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("p", { staticClass: "p-title" }, [
              _vm._v(_vm._s(_vm.FormMSG(27, "Reservation date"))),
            ]),
            _c("p", [
              _vm._v(
                "\n\t\t\t\t" +
                  _vm._s(
                    _vm.accomodation.acc !== undefined &&
                      _vm.getDate(_vm.accomodation.startDate, "DD MMMM YYYY")
                  ) +
                  "\n\t\t\t\t"
              ),
              _c("span", { staticClass: "divider-dot" }),
              _vm._v(
                "\n\t\t\t\t" +
                  _vm._s(
                    _vm.accomodation.acc !== undefined &&
                      _vm.getDate(_vm.accomodation.endDate, "DD MMMM YYYY")
                  ) +
                  "\n\t\t\t"
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("p", { staticClass: "p-title" }, [
              _vm._v(_vm._s(_vm.FormMSG(29, "Instructions"))),
            ]),
            _c("p", [
              _vm._v(
                "\n\t\t\t\t" +
                  _vm._s(
                    _vm.accomodation.acc !== undefined &&
                      _vm.accomodation.acc.specialInstruction
                      ? _vm.accomodation.acc.specialInstruction
                      : "-"
                  ) +
                  "\n\t\t\t"
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("p", { staticClass: "p-title fsz-16" }, [
              _vm._v(
                "\n\t\t\t\t" +
                  _vm._s(
                    _vm.accomodation.acc !== undefined &&
                      _vm.accomodation.acc.category === 0
                      ? _vm.FormMSG(30, "Place Information")
                      : _vm.FormMSG(31, "Routes")
                  ) +
                  "\n\t\t\t"
              ),
            ]),
          ]),
        ],
        1
      ),
      _vm.accomodation.acc !== undefined && _vm.accomodation.acc.category === 0
        ? _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "4" } }, [
                _c("p", [
                  _c("strong", [_vm._v(_vm._s(_vm.FormMSG(4, "Name")))]),
                ]),
              ]),
              _c("b-col", { attrs: { cols: "8" } }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(
                      _vm.accomodation.acc !== undefined &&
                        _vm.accomodation.acc.name
                        ? _vm.accomodation.acc.name
                        : "-"
                    ) +
                    "\n\t\t"
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.accomodation.acc !== undefined && _vm.accomodation.acc.category === 0
        ? _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "4" } }, [
                _c("p", [
                  _c("strong", [_vm._v(_vm._s(_vm.FormMSG(5, "Phone")))]),
                ]),
              ]),
              _c(
                "b-col",
                { attrs: { cols: "8" }, on: { click: _vm.callPhone } },
                [
                  _c("span", { staticClass: "text-primary" }, [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(
                          _vm.accomodation.acc !== undefined &&
                            _vm.accomodation.acc.supplier
                            ? _vm.accomodation.acc.supplier.phone
                            : "-"
                        ) +
                        "\n\t\t\t"
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.accomodation.acc !== undefined && _vm.accomodation.acc.category === 0
        ? _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "4" } }, [
                _c("p", [
                  _c("strong", [_vm._v(_vm._s(_vm.FormMSG(6, "Email")))]),
                ]),
              ]),
              _c("b-col", { attrs: { cols: "8" } }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(
                      _vm.accomodation.acc !== undefined &&
                        _vm.accomodation.acc.supplier
                        ? _vm.accomodation.acc.supplier.email
                        : "-"
                    ) +
                    "\n\t\t"
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.accomodation.acc !== undefined && _vm.accomodation.acc.category !== 0
        ? _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "4" } }, [
                _c("p", [
                  _c("strong", [_vm._v(_vm._s(_vm.FormMSG(33, "From")))]),
                ]),
              ]),
              _c("b-col", { attrs: { cols: "8" } }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(
                      _vm.accomodation.acc !== undefined &&
                        _vm.accomodation.acc.fromAddress
                        ? _vm.accomodation.acc.fromAddress
                        : "-"
                    ) +
                    "\n\t\t"
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.accomodation.acc !== undefined && _vm.accomodation.acc.category !== 0
        ? _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "4" } }, [
                _c("p", [
                  _c("strong", [_vm._v(_vm._s(_vm.FormMSG(32, "To")))]),
                ]),
              ]),
              _c(
                "b-col",
                { attrs: { cols: "8" }, on: { click: _vm.callPhone } },
                [
                  _vm._v(
                    "\n\t\t\t" +
                      _vm._s(
                        _vm.accomodation.acc !== undefined &&
                          _vm.accomodation.acc.toAddress
                          ? _vm.accomodation.acc.toAddress
                          : "-"
                      ) +
                      "\n\t\t"
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("p", { staticClass: "p-title fsz-16" }, [
              _vm._v(
                "\n\t\t\t\t" + _vm._s(_vm.FormMSG(28, "Address")) + "\n\t\t\t"
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", [
            _vm._v(
              "\n\t\t\t" +
                _vm._s(
                  _vm.accomodation.acc !== undefined &&
                    _vm.accomodation.acc.supplier
                    ? _vm.getAddressFormater(
                        _vm.accomodation.acc.supplier.address
                      )
                    : "-"
                ) +
                "\n\t\t"
            ),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "light", block: "" },
                  on: { click: _vm.handleClickDirection },
                },
                [_c("MapPin", { attrs: { color: "#06263ED6", size: 16 } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }